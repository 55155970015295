<template>
  <section
    v-if="sidebarComponents.length === 0"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <template v-if="variants.v1 === variant">
      <h3>{{ vars.titleText }}</h3>
      <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-10 tw-pt-10 lg:tw-flex-col-reverse">
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="true"
          :slides-per-view="1"
          :space-between="20"
          effect="fade"
          :clickable="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }"
          @swiper="onSwiper"
          class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(property, index) in properties" :key="`property-1-${index}`">
            <theme4-properties-property-list-item :property="property" :is-match-information-hidden="true" />
          </SwiperSlide>
        </Swiper>
        <nuxt-link
          :external="true"
          v-if="vars.buttonLabelText"
          :class="`n-${vars.buttonTypeText}`"
          :to="vars.buttonLinkUrl"
        >
          {{ vars.buttonLabelText }}
        </nuxt-link>
      </div>
    </template>

    <template v-if="variants.v2 === variant">
      <div class="tw-flex tw-w-full tw-max-w-lg tw-flex-col tw-items-center tw-text-black lg:tw-max-w-none">
        <h2 class="tw-mb-10 lg:tw-mb-2">{{ vars.titleText }}</h2>
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-10 tw-px-2 lg:tw-flex-col-reverse">
          <Swiper
            :modules="[SwiperPagination]"
            :pagination="true"
            :slides-per-view="1"
            :space-between="20"
            effect="fade"
            :clickable="true"
            :watch-overflow="true"
            @slideChange="onSlideChange"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }"
            @swiper="onSwiper"
            class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
          >
            <SwiperSlide
              v-for="(property, index) in properties"
              :key="`latestproperties-property-${index}`"
              @click="selectedProperty = property.featured_video ? property.featured_video : null"
            >
              <theme4-properties-property-list-item
                :property="property"
                :is-match-information-hidden="true"
                version="v2"
                :pre-market-property-feature="true"
                @open-video-popup="isVisible = true"
              />
            </SwiperSlide>
          </Swiper>
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v3"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>
        <nuxt-link :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText} lg:tw-mt-14`">{{
          vars.buttonLabelText
        }}</nuxt-link>
      </div>
      <library-popups-video-popup
        v-if="selectedProperty?.mediaLink"
        :videoUrl="selectedProperty?.mediaLink"
        v-model:is-visible="isVisible"
        :portrait="true"
      />
    </template>
  </section>

  <template v-else-if="sidebarComponents.length > 0">
    <div
      class="tw-border tw-bg-white tw-px-6 tw-py-8"
      style="border-radius: var(--rounded)"
      v-if="variants.v1 === variant"
    >
      <h3 class="tw-pb-6">{{ vars.titleText }}</h3>
      <Swiper
        :modules="[SwiperPagination]"
        :pagination="true"
        :slides-per-view="1"
        :space-between="20"
        effect="fade"
        :clickable="true"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="(property, index) in properties" :key="`property-2-${index}`">
          <theme4-properties-property-list-item :property="property" :is-match-information-hidden="true" />
        </SwiperSlide>
      </Swiper>
    </div>

    <div v-if="variants.v2 === variant">
      <div
        class="tw-px-4 tw-pb-0 tw-pt-8 tw-text-black"
        style="border-radius: var(--rounded)"
        :style="`background-color: var(--c-${vars.backgroundColor})`"
      >
        <h3>{{ vars.titleText }}</h3>
        <div class="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-px-2">
          <Swiper
            :modules="[SwiperPagination]"
            :pagination="false"
            :slides-per-view="1"
            :space-between="20"
            effect="fade"
            :clickable="true"
            :watch-overflow="true"
            @slideChange="onSlideChange"
            @swiper="onSwiper"
            class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
          >
            <SwiperSlide
              v-for="(property, index) in properties"
              :key="`latestproperties-property-${index}`"
              @click="selectedProperty = property.featured_video ? property.featured_video : null"
            >
              <theme4-properties-property-list-item
                :property="property"
                :is-match-information-hidden="true"
                version="v2"
                :pre-market-property-feature="true"
                class="sidebar-property-list-item"
                @open-video-popup="isVisible = true"
              />
            </SwiperSlide>
          </Swiper>
          <div>
            <LibrarySliderPagination
              :swiper="swiper"
              variant="v3"
              :can-paginate-back="canPaginateBack"
              :can-paginate-next="canPaginateNext"
            />
          </div>
        </div>
        <nuxt-link :to="vars.buttonLinkUrl" :class="`n-${vars.buttonTypeText} lg:tw-mt-14`">{{
          vars.buttonLabelText
        }}</nuxt-link>
      </div>
      <library-popups-video-popup
        v-if="selectedProperty?.mediaLink"
        :videoUrl="selectedProperty?.mediaLink"
        v-model:is-visible="isVisible"
        :portrait="true"
      />
    </div>
  </template>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertiesMixin } from '~/units/properties/mixins/properties.mixin';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme4LatestProperties',

  mixins: [ComponentMixin, PropertiesMixin],

  async setup(props) {
    await fetchPropertiesManager(props.component, null);
  },

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      selectedProperty: null,
      isVisible: false,
      canPaginateNext: true,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },

    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  mounted() {
    this.onSlideChange();
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>

<style scoped>
::v-deep(.v3-left-arrow),
::v-deep(.v3-right-arrow) {
  margin-top: -100px;
}

.sidebar-property-list-item ::v-deep(.property-list-item) {
  min-height: unset !important;
}
</style>
